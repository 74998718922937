
export default {
  // name: 'NavList',
  props: {
    items: {
      required: true,
      type: Array,
    },
    keyNamespace: {
      default: 'nav-item',
      type: String,
    },
  },
  methods: {
    getTitleClass (item) {
      const colorClass = `${item.color ?? ''}--text`

      return {
        'font-weight-bold': (!item.to && !item.href) || !!item.color,
        [colorClass]: !!item.color,
      }
    },
  },
}
