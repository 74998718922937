import { render, staticRenderFns } from "./login.vue?vue&type=template&id=2e5cab1c&lang=pug"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotificationBanner: require('/srv/www/web-smi/components/NotificationBanner.vue').default,Footer: require('/srv/www/web-smi/components/Footer.vue').default})
