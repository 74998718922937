import { render, staticRenderFns } from "./main.vue?vue&type=template&id=41d8b7a8&lang=pug"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/srv/www/web-smi/components/Navigation.vue').default,ImpersonationBanner: require('/srv/www/web-smi/components/ImpersonationBanner.vue').default,NotificationBanner: require('/srv/www/web-smi/components/NotificationBanner.vue').default,Footer: require('/srv/www/web-smi/components/Footer.vue').default})
